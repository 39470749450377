import { Table as BaseTable, Button, Center, Collapse, LoadingOverlay, Pagination, ScrollArea, Select, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconChevronUp, IconDatabaseOff, IconMinus, IconPlus, IconSelector } from "@tabler/icons-react";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";

export * from "./RowAction";

export type ColumnType<T> = {
  title: string | React.ReactElement;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  renderTitle?: (data: any | { count: number; rows: any[] }, record: any, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  columns: ColumnType<any>[];
  filters?: { [key: string]: string | number | any };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  collapse?: boolean;
  collapseChildren?: any;
  limit?: number;
};

export type ITableRef = { reload: () => void };

export const Table = React.forwardRef(
  (
    { name, columns, filters = {}, pagination = true, dataSource = [], loadData, collapse = false, collapseChildren, limit = 10 }: Props,
    ref: React.Ref<ITableRef>,
  ) => {
    const { classes } = useStyles();
    const [opened, setOpened] = React.useState<number[]>([]);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const tableRef = React.useRef<HTMLTableElement>(null);
    const [offset, setOffset] = React.useState({ page: 1, limit: limit });
    const [scrolled, setScrolled] = React.useState<any>(0);

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
      async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );

    const [sorted, setSorting] = React.useState<string[]>([]);

    const onSort = (index?: string) => {
      if (index) {
        setSorting((state) => [index, state[0] === index ? (state[1] === "asc" ? "desc" : "asc") : "asc"]);
      }
    };

    useImperativeHandle(ref, () => ({
      reload() {
        return mutate();
      },
    }));

    return (
      <div className={classes.container}>
        <ScrollArea onScrollPositionChange={({ x }) => setScrolled(x)} ref={scrollRef} className={classes.scroll}>
          <BaseTable ref={tableRef} horizontalSpacing="md" verticalSpacing="xs" highlightOnHover striped="even">
            <BaseTable.Thead>
              <BaseTable.Tr>
                {collapse && <BaseTable.Th className={classes.collapseTh}></BaseTable.Th>}
                {columns.map((column, index) => (
                  <Th
                    key={index}
                    column={column}
                    sorted={sorted[0] === column.dataIndex && sorted[1]}
                    onSort={() => onSort(column.dataIndex)}
                    index={index}
                    scrolled={scrolled}>
                    {column.title}
                    {column?.renderTitle && column.renderTitle(data, column, index)}
                  </Th>
                ))}
              </BaseTable.Tr>
            </BaseTable.Thead>
            <BaseTable.Tbody>
              {data?.rows?.length > 0 ? (
                data.rows.map((row: any, index: number) => {
                  const isOpen = opened.includes(index);
                  return (
                    <BaseTable.Tr key={`row-${row?.id ?? index}`}>
                      {collapse && (
                        <BaseTable.Td rowSpan={isOpen ? 2 : 1}>
                          <Button
                            onClick={() => setOpened(isOpen ? opened.filter((f) => f !== index) : [...opened, index])}
                            size="xs"
                            w={25}
                            h={25}
                            p={0}
                            variant="default">
                            {isOpen ? <IconMinus size={18} stroke={1} /> : <IconPlus size={18} stroke={1} />}
                          </Button>
                        </BaseTable.Td>
                      )}
                      {columns.map((column, index2: number) => (
                        <BaseTable.Td key={`cell-${row?.id ?? index}-${index2}`} align={column.align || "left"}>
                          {column.render(row, index + offset.page * offset.limit - offset.limit)}
                        </BaseTable.Td>
                      ))}
                      {collapse && isOpen && (
                        <BaseTable.Tr key={`collapse-${row?.id ?? index}`}>
                          <BaseTable.Td colSpan={columns.length}>
                            <Collapse in={isOpen}>
                              <div className={classes.collapseContent}>{collapseChildren && collapseChildren(row)}</div>
                            </Collapse>
                          </BaseTable.Td>
                        </BaseTable.Tr>
                      )}
                    </BaseTable.Tr>
                  );
                })
              ) : (
                <BaseTable.Tr key="nothing">
                  <BaseTable.Td colSpan={collapse ? columns.length + 1 : columns.length}>
                    <Center sx={(theme) => ({ padding: theme.spacing.xl, flexDirection: "column", gap: 10 })}>
                      <IconDatabaseOff size={36} stroke={1} />
                      <Text fw={500} ta="center">
                        Өгөгдөл олдсонгүй.
                      </Text>
                    </Center>
                  </BaseTable.Td>
                </BaseTable.Tr>
              )}
            </BaseTable.Tbody>
          </BaseTable>

          <LoadingOverlay zIndex={1} visible={isLoading} />
          <Space h={"sm"} />
        </ScrollArea>

        {pagination && (
          <div className={classes.pagination}>
            <Pagination
              total={Math.ceil(data.count / offset.limit)}
              value={offset.page}
              onChange={(page) => setOffset((state) => ({ ...state, page }))}
              siblings={1}
              boundaries={1}
              withControls={true}
              disabled={Math.ceil(data.count / offset.limit) <= 1}
              hideWithOnePage
            />
            {data.count > 0 && data && (
              <Select
                onChange={(e: string | null) => {
                  if (e !== null) {
                    setOffset((state) => ({ ...state, limit: parseInt(e, 10), page: 1 }));
                  }
                }}
                value={`${offset.limit}`}
                w={"100px"}
                size="xs"
                defaultValue={"10"}
                data={["5", "10", "20", "30", "50", "100", "200"]}
              />
            )}
          </div>
        )}
      </div>
    );
  },
);

interface ThProps {
  children: React.ReactNode;
  column: ColumnType<any>;
  sorted?: string | unknown;
  onSort(): void;
  index: number;
  scrolled: number;
}

const Th = ({ children, column, sorted, onSort, index, scrolled }: ThProps) => {
  const { classes } = useStyles();
  const Icon = sorted ? (sorted === "asc" ? IconChevronUp : IconChevronDown) : IconSelector;

  if (!column.sorter)
    return (
      <BaseTable.Th style={{ width: column.width }}>
        <span className={classes.child}>{children}</span>
      </BaseTable.Th>
    );

  return (
    <BaseTable.Th className={`${classes.control}`} style={{ width: column.width || "max-content" }} onClick={() => onSort()}>
      <div className={classes.button}>
        <span className={classes.child}>{children}</span>
        <span className={classes.icon}>
          <Icon size={14} stroke={1.5} />
        </span>
      </div>
    </BaseTable.Th>
  );
};

const useStyles = createStyles((theme) => ({
  collapseTh: {
    width: "24px",
  },
  collapseContent: {
    borderTop: "1px solid #dadada",
    padding: "14px 24px",
  },
  container: {
    position: "relative",
  },
  scroll: {
    overflowX: "auto",
    width: "100%",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  icon: {
    display: "flex",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 0",
    alignItems: "center",
    gap: 10,
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));
